<template>
  <div class="coupon">
    <!-- <van-nav-bar title="卡券兑换" left-arrow @click-left="onClickLeft" /> -->
    <van-coupon-list
      :coupons="coupons"
      :disabled-coupons="disabledCoupons"
      :show-close-button=false
      :show-exchange-bar="false"
      enabled-title="可使用"
      disabled-title="已使用"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { getCouponList } from '@/service/user'
export default {
  setup() {
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    const onClickLeft = () => history.back();
    const loadData = async () => {
      let res0 = await getCouponList(token.userId,0)
      if(res0.data) {
        coupons.value = res0.data.data;
      }
      let res1 = await getCouponList(token.userId,1)
      if(res1.data) {
        disabledCoupons.value = res1.data.data;
      }
    }
    // 可使用优惠券
    const coupons = ref([]);
    // 已使用优惠券
    const disabledCoupons = ref([]);


    onMounted(() => {
      loadData()
    })

    return {
      coupons,
      onClickLeft,
      disabledCoupons,
    }
  }
}
</script>

<style lang="scss">
  .coupon {
    display: flex;
    flex-direction: column;
    .van-coupon-list {
      flex: 1;
      .van-coupon {
        .van-checkbox {
          display: none;
        }
      }
      .van-coupon-list__bottom {
        display: none;
      }
    }
  }
</style>
